import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import config from '../package.json'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import { key, store } from './store'

const app = createApp(App)

const environment = import.meta.env.VITE_ENVIRONMENT as string
if (environment !== 'dev') {
  switch(import.meta.env.VITE_APP_BRAND) {
    case 'CetuSchool': {
      Sentry.init({
        app,
        dsn: 'https://7a6a160921ba43eca5cba70d8a6d4970@o1110879.ingest.sentry.io/6171015',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-student@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['student.staging.cetuschool.com', 'student.cetuschool.com', /^\//],
          })
        ]
      })
      break
    }
    case 'DDTalk': {
      Sentry.init({
        app,
        dsn: 'https://6e038809f99448c09279b9dfb31eb715@o4504817848549376.ingest.sentry.io/4504829190668288',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-student@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['student.staging.ddtalk.me', 'student.ddtalk.me', /^\//],
          })
        ]
      })
      break
    }
  }
}

let icon = ''
let theme = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    icon = '/icon/cetuschool.ico'
    theme = '/css/theme/cetuschool.min.css'
    break
  }
  case 'DDTalk': {
    icon = '/icon/ddtalk.ico'
    theme = '/css/theme/ddtalk.min.css'
    break
  }
}
const head = createHead({
  title: import.meta.env.VITE_APP_TITLE,
  link: [
    {
      rel: 'icon',
      href: icon
    },
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: theme
    }
  ]
})

app.use(head)
  .use(i18n)
  .use(router)
  .use(store, key)
  .mount('#app')