<template>
  <div class="min-vh-100 bg-primary">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid px-0">
        <!-- Brand -->
        <a class="navbar-brand" href="#">
				  <span>{{brand}}</span>
        </a>

        <!-- Locale -->
        <form>
          <select v-model="locale" class="form-select" @change="doChangeLocale">
            <option v-for="(availableLocale, index) in availableLocales" :key="index" :value="availableLocale">{{ LocaleFilter.locale(availableLocale) }}</option>
          </select>
        </form>
      </div>
    </nav>

    <div class="container">
      <div class="row align-items-center justify-content-center g-0" style="height: calc(100vh - 70px);">
        <div class="col-lg-5 col-md-8 py-8 py-xl-0" style="width: 700px;">
          <!-- Card -->
          <div class="card shadow">
            <!-- Card body -->
            <div class="card-body p-6">
              <div class="mb-4">
                <h1 class="mb-5 fw-bold">{{ t('title.auth') }} {{ brand }}</h1>
                <!-- <div class="buttons">
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="mdi mdi-google me-2 text-danger"></i>Google</button>
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="mdi mdi-twitter me-2 text-info"></i>Twitter</button>
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="mdi mdi-facebook me-2 text-primary"></i>Facebook</button>
                </div> -->
              </div>

              <!-- divider -->
              <!-- <it-divider>{{ t('text.or') }}</it-divider> -->

              <it-tabs class="mt-4" :active-index="activeTabIndex">
                <template #tabs>
                  <it-tab :index="1" @select="onTabSelect">{{ t('tab.auth.mobile') }}</it-tab>
                  <it-tab :index="2" @select="onTabSelect">{{ t('tab.auth.email') }}</it-tab>
                </template>

                <template #panes>
                  <it-tab-pane :index="1">
                    <mobile-otp-auth ref="mobileOtpAuthRef" />
                  </it-tab-pane>
                  <it-tab-pane :index="2">
                    <email-otp-auth ref="emailOtpAuthRef" />
                  </it-tab-pane>
                </template>
              </it-tabs>
              
              <div class="mt-2 text-center" style="color: red;">{{ t('note.signup-reward') }}</div>
            </div>
            <!-- Card Footer -->
            <div class="card-footer bg-white px-6 py-4">
              <p class="mb-0 text-center">{{ t('text.statement') }} <a href="#" class="text-inherit fw-semi-bold">{{ t('text.terms-of-use') }}</a> {{ t('text.and') }} <a href="#" class="text-inherit fw-semi-bold">{{ t('text.privacy-policy') }}</a></p>
            </div>
          </div>

          <!-- Version -->
          <div class="text-center mt-3" style="color: gainsboro;">- {{ version }} -</div>
        </div>
      </div>
    </div>
		
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import config from '../../package.json'
import EmailOtpAuth from '../components/auth/EmailOtpAuth.vue'
import MobileOtpAuth from '../components/auth/MobileOtpAuth.vue'
import ItTab from '../components/ui/tab/ItTab.vue'
import ItTabPane from '../components/ui/tab/ItTabPane.vue'
import ItTabs from '../components/ui/tab/ItTabs.vue'
import { LocaleFilter } from '../filters/localeFilter'
import { Actions, Mutations, useStore } from '../store'

const { t, locale, availableLocales } = useI18n({
  useScope: 'global'
})

const activeTabIndex = ref<number>(1)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}

const brand = import.meta.env.VITE_APP_BRAND
const version = computed(() => config.version)

const route = useRoute()
const router = useRouter()
const store = useStore()

router.isReady().then(() => {
  if(route.params.promoteCode) store.commit(Mutations.SET_PROMOTE_CODE, route.params.promoteCode)
})

const emailOtpAuthRef = ref()
const mobileOtpAuthRef = ref()
const doChangeLocale = function() {
  store.dispatch(Actions.CHANGE_LOCALE, locale.value).then(() => {
    if (emailOtpAuthRef.value) emailOtpAuthRef.value.onLocaleChange()
    if (mobileOtpAuthRef.value) mobileOtpAuthRef.value.onLocaleChange()
  })
}
</script>