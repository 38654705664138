<template>
  <!-- Form -->
  <form ref="authForm" class="mt-2" :class="{'was-validated': authFormValidated}" novalidate>
    <!-- Alert -->
    <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
    <!-- Email -->
    <div class="mb-3">
      <label for="email" class="form-label">{{ t('label.email') }}</label>
      <div class="input-group">
        <input ref="emailInput" v-model.trim="auth.email" type="email" id="email" class="form-control" name="email" :placeholder="t('placeholder.email')" required @input="onEmailInput">
        <it-button kind="primary" class="rounded-end" outline style="width: 140px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendAuthOtp">{{ optButtonText }}</it-button>
        <div class="invalid-feedback" :class="{invalid: emailInputInvalid}">{{ t('validator.invalidate-email') }}</div>
      </div>
    </div>
    <!-- OTP -->
    <div class="mb-3">
      <label for="otp" class="form-label">{{ t('label.otp') }}</label>
      <input v-model.trim="auth.otp" type="text" id="otp" class="form-control" name="otp" :placeholder="t('placeholder.otp')" required pattern="\d{6}" />
      <div class="invalid-feedback">{{ t('validator.invalidate-otp') }}</div>
    </div>
    <!-- Button -->
    <div class="d-grid">
      <it-button kind="primary" :loading="authing" @click="doAuth">{{ t('button.auth') }}</it-button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import authAPI from '../../api/auth'
import emailAPI from '../../api/email'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { ParentAuthed, ParentEmailOtpAuth } from '../../types/auth'
import { Result } from '../../types/common/result'
import ItButton from '../ui/ItButton.vue'

const { t, locale } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const message = ref<string>('')

const auth = reactive<ParentEmailOtpAuth>(new ParentEmailOtpAuth())
const authForm = ref<HTMLFormElement>()

const optButtonText = ref<string>(t('button.get-otp'))
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = t('button.get-otp')
  disableOtpButton.value = false
  countDown = 30
})

const emailInput = ref<HTMLInputElement>()
const emailInputInvalid = ref<boolean>(false)
const onEmailInput = function() {
  emailInput.value?.setCustomValidity('')
  emailInputInvalid.value = false
}

const doSendAuthOtp = function() {
  if (!emailInput.value?.checkValidity()) {
    emailInputInvalid.value = true
  }
  
  if (auth.email) {
    sendingOtp.value = true
    disableOtpButton.value = true
    emailAPI.sendAuthOtp(auth.email).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success(t('message.send-otp-success'))
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  }
}

const authing = ref<boolean>(false)
const authFormValidated = ref<boolean>(false)
const doAuth = function() {
  authFormValidated.value = true
  if (authForm.value?.checkValidity()) {
    authing.value = true
    // 登录或注册
    auth.promoteCode = store.getters.promoteCode
    authAPI.emailOtpAuth(auth).then((response: AxiosResponse<Result<ParentAuthed>>) => {
      const result = response.data
      if (result.success) {
        const authed = result.data
        const parent = authed.parent
        if (parent.locked) { // 账户锁定
          message.value = t('message.account-is-locked')
        } else {
          // 存储家长信息
          store.dispatch(Actions.AUTH, authed).then(() => {
            if (parent.complete) {
              // 同步语言
              if (locale.value !== parent.locale) locale.value = parent.locale
              store.dispatch(Actions.CHANGE_LOCALE, parent.locale)
              // 同步时区
              store.dispatch(Actions.CHANGE_ZONE_ID, parent.zoneId)
              // 跳转
              router.push({
                name: 'schedule'
              })
            } else {
              router.push({
                name: 'supplement'
              })
            }
          })
        }
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      const response = error.response
      if (response) {
        if (response.status === 401) {
          message.value = t('message.otp-is-wrong')
        } else {
          message.value = error.message
        }
      } else {
        toastRef?.value?.error(error.message)
      }
    }).finally(() => {
      authing.value = false
    })
  }
}

const onLocaleChange = function() {
  optButtonText.value = t('button.get-otp')
}

defineExpose({
  onLocaleChange
})
</script>