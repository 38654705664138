import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"
import { store } from '../store'

const config: AxiosRequestConfig = {
	baseURL: import.meta.env.VITE_API_URL as string,
	timeout: 1000000,
	responseType: 'json',
	headers: { 
		'Access-Control-Allow-Origin': '*',
		'Client': 'Student'
	}
}

const fileService: AxiosInstance = axios.create(config)

// request拦截器
fileService.interceptors.request.use(
	(config: InternalAxiosRequestConfig<any>) => {
		if (store.getters.isAuth && config.headers) {
			config.headers!.Authorization = 'Bearer ' + store.state.accessToken
		}

		return config
	},
	(error: any) => Promise.reject(error)
)

export default fileService