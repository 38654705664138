import storage from "../../storage"
import { AuthParent } from "../auth"

export interface State {
	parent: AuthParent
	promoteCode?: string
	accessToken?: string
	refreshToken?: string
}

export class StateImpl implements State {
	parent = storage.getParent() || new AuthParent()
	accessToken = storage.getAccessToken()
	refreshToken = storage.getRefreshToken()
}